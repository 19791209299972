// 文化 - 关怀
import { CULTURE } from '../api'
import { METHOD, request } from '@/utils/request'

// 查询某月生日员工列表
export async function getBirthList(params) {
  return request(`${CULTURE}/care/getBirthDateList`, METHOD.GET, params)
}

// 查询某月周年员工列表
export async function getYearList(params) {
  return request(`${CULTURE}/care/getAnniversaryList`, METHOD.GET, params)
}

// 查询海报列表
export async function getPosterList(params) {
  return request(`${CULTURE}/care/getPosterList`, METHOD.GET, params)
}

// 查询祝福语列表
export async function getWishList(params) {
  return request(`${CULTURE}/care/getBlessList`, METHOD.GET, params)
}

// 添加祝福语
export async function addWish(params) {
  return request(`${CULTURE}/care/addBless`, METHOD.POST, params)
}

// 删除祝福语
export async function delBless(params) {
  return request(`${CULTURE}/care/delBless`, METHOD.POST, params)
}

// 添加海报
export async function addPoster(params) {
  return request(`${CULTURE}/care/addPoster`, METHOD.POST, params)
}

// 删除海报
export async function delPoster(params) {
  return request(`${CULTURE}/care/delPoster`, METHOD.POST, params)
}

// 完成设置
export async function completeSetting(params) {
  return request(`${CULTURE}/care/setCareSetting`, METHOD.POST, params)
}

// 查询关怀设置
export async function searchSetting(params) {
  return request(`${CULTURE}/care/getCareSetting`, METHOD.GET, params)
}

// 查询颜色列表
export async function ColorPicker(params) {
  return request(`${CULTURE}/care/getCareBlessColorList`, METHOD.GET, params)
}
